import { Component, OnInit, ViewChild } from '@angular/core';
import { DataBindingDirective, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs/internal/Observable';
import { GroupDescriptor, State } from '@progress/kendo-data-query';
import { AllSarService } from '../../services/all-sar-service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, switchMap } from 'rxjs/operators';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { CommonService } from 'src/app/core/services/common.service';
import { RoleConstant } from 'src/app/core/constants/role.constants';
import { ActivatedRoute, Router } from '@angular/router';
import { requestConst } from 'src/app/core/constants/request.const';

@Component({

  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],

})
export class OrdersComponent implements OnInit{
  @ViewChild(DataBindingDirective)
  dataBinding!: DataBindingDirective;

  public loadingPanelVisible = false;
  public multiple = false;
  public allowUnsort = true;
  public gridView: any;
  public skip= 0;
  public pageSize= 10;
  public sort!: any;
  public groups: GroupDescriptor[]=[];
  public filters!:any;
  public buttonCount = 10;
  public info = true;
  public pageSizes = true;
  public previousNext = true;
  public totalRecords= 0;
  public userBelongsToSensitiveGroup: boolean = false;
  public searchUserRequestControl = new FormControl();
  public osdata: Array<string> = [];
  public isAdmin = false;
  public buName: string="";
  private currentUserCai: string="";
  private displayName: string="";

  public state: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: "and",
      filters: [{ field: "", operator: "", value: "" }]
    },
  };
  public body = {
    "buName": this.buName,
    "isAdminUser": this.isAdmin,
    "dataSourceRequest": this.state
  }
  constructor(public myRequestsService: AllSarService,
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute
    //  private toastService: ToastService,
    // private spinnerService: SpinnerService
  ) {
    this.searchUserRequestControl.valueChanges.pipe(
      debounceTime(600),
      switchMap(async (changedValue) => this.onFilterSearch(changedValue)),
    ).subscribe();


  }
  public ngOnInit(): void {
    this.loadGrid();
  }

  public groupChange(groups: GroupDescriptor[]): void {
    this.state.group = groups;
    this.groups=groups;
    this.loadGrid();
  }

  private loadGrid(): void {
    this.loadingPanelVisible = true;
    if (this.commonService.getUserRoleName() === RoleConstant.ESAR_Admin) {
      this.isAdmin = true;
    }
    if(!this.isAdmin){
      this.buName = this.commonService.getUserBUName();
    }
    this.body.isAdminUser = this.isAdmin;
    this.body.buName = this.buName;
    this.BindDataTogrid();

  }


  private BindDataTogrid():void{
    this.myRequestsService.getAllRequestsData(this.body).subscribe(result=>{
      if(this.groups.length > 0){
        let data= result as any;
        this.gridView = {
          data: data?.groups,
          total: data?.total,
        };
        this.totalRecords=this.gridView?.total??0;
      }
      else{
        this.gridView =result;
        this.totalRecords=this.gridView?.total??0;
      }
      this.loadingPanelVisible = false;
    });
  }


  public allData = (): Observable<any> => {
    this.body.dataSourceRequest.take =  this.gridView?.total;
    const observable = this.myRequestsService.getAllRequestsData((this.body));
    observable.subscribe((result) => {
    });

    return observable;
  };

  public onFilter(inputValue: any): void {
    this.loadingPanelVisible = true;
    let serachtext=inputValue?.target.value??"";
    if(serachtext!=null && serachtext!=undefined && serachtext!=""){
      this.state.filter = this.filters;
      this.state.filter = this.filters;
      this.myRequestsService.getAllRequestsData(this.body)
        .subscribe(result=>{
          this.gridView = result;
          this.totalRecords = this.gridView?.total ?? 0;
          this.loadingPanelVisible = false;
        });
    }
    else{
      this.loadGrid();
    }
  }

  public onFilterSearch(inputValue: any): void {
    this.loadingPanelVisible = true;
    let serachtext = inputValue ?? "";

    this.state.filter = this.filters;

    let searchFilter = this.getFilterObjectForSearch(serachtext)
    if (this.filters == null || this.filters == undefined) {
      this.filters = searchFilter;
    }
    else if (this.filters.filters.filter((item: { field: string; }) => item.field === "searchtext").length == 0) {
      this.filters.filters.push(searchFilter.filters[0]);
    }
    else {
      this.filters.filters.filter((item: { field: string; }) => item.field === "searchtext")[0].value = serachtext;
    }
    this.state.filter=this.filters;
    this.state.skip=0;
    this.state.take=this.pageSize;

    this.myRequestsService.getAllRequestsData(this.body)
      .subscribe(result => {
        this.gridView = result;
        this.loadingPanelVisible = false;
        this.totalRecords = this.gridView?.total ?? 0;
      });

  }

  private getStateObjectForSearch(searchText:string):State{
    return {
      skip: 0,
      take: this.pageSize,
      filter:{
        logic:"or",
        filters:[
          {
            field: "searchtext",
            operator: "contains",
            value: searchText,
          }
        ]
      }
    };
  }

  private getFilterObjectForSearch(searchText: string): any {
    return {
      logic: "and",
      filters: [
        {
          field: "searchtext",
          operator: "contains",
          value: searchText,
        }
      ]
    };
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.filters = state.filter;

    let searchFilter = this.getFilterObjectForSearch(this.searchUserRequestControl.value?? "")
    if (this.filters == null || this.filters == undefined) {
      this.filters = searchFilter;
    }
    else if (this.filters.filters.filter((item: { field: string; }) => item.field === "searchtext").length == 0) {
      this.filters.filters.push(searchFilter.filters[0]);
    }
    else {
      this.filters.filters.filter((item: { field: string; }) => item.field === "searchtext")[0].value = this.searchUserRequestControl.value ?? "";
    }


    this.state.filter=this.filters;
    this.state.skip=state.skip;
    this.state.take=state.take;
    this.skip=state.skip;
    this.pageSize=state.take;
    this.state.sort = state.sort;
    this.sort = state.sort;
    this.loadGrid();
  }

  @ViewChild(TooltipDirective)
  public tooltipDir!: TooltipDirective;
  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if (
      (element.nodeName === "TD" || element.nodeName === "TH") &&
      element.offsetWidth < element.scrollWidth
    ) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  manage(requestId: number) {
    const path = `false/false/${requestId}`;    
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`../sar/${requestConst.viewSAR}/${path}`], {        
        relativeTo: this.route
      })
    );
    window.open(url, '_blank');
  }
  
  navViewAction(dataItem: any) {
    let changeType: string = dataItem.action ? dataItem.action.split(' ').join('_') : '';
    if (dataItem.status !== 'Draft') {
      this.router.navigate([`../sar/${requestConst.approvalDetail}/${dataItem.id}/${dataItem.sarId}/${dataItem.issueId}/${dataItem?.actionNumber}/${changeType}`],
        { state: { requestId: dataItem.id, requestNumber: dataItem.sarId, requestIssueId: dataItem.issueId, actionNumber: dataItem?.actionNumber, changeType: dataItem.action, createdBY: dataItem.createdBy }, relativeTo: this.route });
    }
  }
}
